import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import {
  Grid,
  Card,
  CardMedia,
  Typography,
  useMediaQuery,
} from "@material-ui/core"

const useStyles = makeStyles(theme => ({
  outer: {
    padding: "6px 10px 6px 10px",
  },
  root: {
    flexGrow: 1,
    textAlign: "left",
    borderRadius: "25px",
    boxShadow: "none",
    backgroundColor: "transparent",
    backdropFilter: "blur(1px)",
    color: "#474747",
  },
  title: {
    fontFamily: "Work Sans",
    textAlign: "center",
    fontSize: "1.4rem",
    color: "#474747",
    marginTop: "20px",
    padding: "0px 20px 0px 20px",
  },
  date: {
    fontFamily: "Work Sans",
    fontSize: "1.1rem",
    textAlign: "center",
  },
  media: {
    borderRadius: "25px",
    boxShadow:
      "rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;",
    [theme.breakpoints.up("sm")]: {
      margin: "30px 0px 30px 0px",
    },
  },
  text: {
    fontSize: "1.05rem",
    fontFamily: "Open Sans",
    padding: "0px 20px 20px 20px",
    "& a": {
      color: "#474747",
    },
  },
  hr: {
    border: "1px solid #0072ab",
    width: "15%",
  },
}))

export default function Event(props) {
  const classes = useStyles()
  const isPhone = useMediaQuery("(max-width: 600px)")
  return (
    <div className={classes.outer}>
      <Card className={classes.root}>
        <Grid
          container
          spacing={0}
          alignItems="center"
          justify="center"
          direction={props.direction}
        >
          <Grid item xs={8} sm={4} lg={3}>
            <CardMedia
              component="img"
              className={classes.media}
              image={props.image}
              title={props.title}
            />
          </Grid>
          <Grid item sm={7}>
            <Typography className={classes.title}>{props.title}</Typography>
            <Typography variant="subtitle1" className={classes.date}>
              {props.date}
            </Typography>
            <hr className={classes.hr} />
            <Typography className={classes.text}>{props.text}</Typography>
            {isPhone && <hr class="shapebreak" />}
          </Grid>
        </Grid>
      </Card>
    </div>
  )
}
