import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import { Grid, Typography, Container } from "@material-ui/core"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import Event from "../components/event"
import Hero from "../components/hero"
import Halloween from "../img/events/halloween.jpg"
import Briscoe from "../img/events/briscoe.jpg"
import Valentines from "../img/events/valentines.jpg"
import Scarlett from "../img/events/scarlett.jpg"
import Northlands from "../img/events/northlands.jpg"
import Woodlands from "../img/events/woodlands.jpg"
import Hariss from "../img/events/hariss.jpg"
import United from "../img/events/united.jpg"
import Movie from "../img/events/movie.jpg"
import Castledon from "../img/events/castledon.jpg"
import Reece from "../img/events/reece.jpg"
import Thundersley from "../img/events/thundersley.jpg"
import PoliceWay from "../img/events/policeway.jpg"
import Skydive from "../img/events/skydive.jpg"
import Sen from "../img/events/sen.jpg"
import Eighties from "../img/events/80s.jpg"
import JJ from "../img/events/jj.jpg"
import Football from "../img/events/football.jpg"
import Proud from "../img/events/proud.jpg"
import { Helmet } from "react-helmet"

export default function Events() {
  return (
    <>
      <Helmet>
        <script src="https://medium-widget.pixelpoint.io/widget.js"></script>
      </Helmet>
      <div id="events">
        <Grid container justify="center" alignItems="center">
          <Grid item sm={9}>
            <Container>
              <Hero
                title="Our Events"
                bodyText="Take a look at our upcomming & past events!"
              />
              <Event
                  title="Halloween Disco"
                  date="28th October 2023"
                  image={Halloween}
                  text="Raising funds for: Anna Kennedy Online & The Reece Hawley Foundation & Southend Prostrate Cancer Support Unit!"
                  direction="row-reverse"
              />
              <Event
                title="Bowers Halloween Disco"
                date="31st October 2020"
                image={Halloween}
                text="Not letting the pandemic get the best of us, we decided to take our planned Halloween Disco online. It was a great success, and we managed to raise £235 for La Danse Fantastique!"
                direction="row"
              />
              <Event
                title="This is Me - Briscoe School"
                date="12th March 2020"
                image={Briscoe}
                text={
                  <div>
                    Another great showing of Aston's film,{" "}
                    <AniLink
                      trigger={async pages => {
                        const entry = await pages.entry

                        const scrollingEl = entry.node.querySelector(
                          "#thisisme"
                        )

                        scrollingEl.scrollTo(0, 0)
                      }}
                      to="/thisisme"
                      paintDrip
                      hex="#d9e5fd"
                    >
                      This is Me
                    </AniLink>
                    . The day was educational for all involved and a great
                    opportunity for parents and carers to learn more about
                    autism. Many tears were had!{" "}
                  </div>
                }
                direction="row-reverse"
              />
              <Event
                title="The Not Valentines Ball"
                date="8th Febuary 2020"
                image={Valentines}
                text={
                  <div>
                    {" "}
                    Just before Valentines Day, we held a ball to allow all of
                    our supporters to get glammed up and enjoy some great food
                    and entertainment. The charities this fundraiser supported
                    were{" "}
                    <a
                      href="https://stlukeshospice.com/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      St. Lukes Hospice
                    </a>{" "}
                    and autism charity{" "}
                    <a
                      href="https://www.annakennedyonline.com/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      AnnaKennedyOnline
                    </a>
                    . We were entertained by the amazing group Abacus and
                    singers David Smith and Mike Barnard. Dawn Avery read a poem
                    written on autism and Aston gave a very moving speech. The
                    night saw us raise an amazing £3001, split equally between
                    both charities. We were also joined by the lovely Vicky
                    Michelle!
                  </div>
                }
                direction="row"
              />
              <Event
                title="Scarlett's Ball"
                date="30th November 2019"
                image={Scarlett}
                text={
                  <div>
                    Scarlett's Ball was a suprise for many people, including us!
                    The ball was arranged and put together by our wonderful
                    friend Lynne Mohseni and her friends, plus a few team
                    members. The aim was to raise funds for{" "}
                    <AniLink to="/pamper/" paintDrip hex="#d9e5fd">
                      Aston's Pamper Project
                    </AniLink>{" "}
                    as a surprise event for us! We had no idea that it was
                    happening until the night! Even the name "Scarlett Kipper"
                    was a fake name she'd set up as a cover. Unti we got there,
                    we had no idea it had all been set up by Lynne! We can't
                    thank her and her friends enough for such a wonderful night,
                    and raising £3000 for Aston's Pamper Project.
                  </div>
                }
                direction="row-reverse"
              />
              <Event
                title="United for Charity Football Match"
                date="August 2019"
                image={United}
                text={
                  <div>
                    Chris Cooper, an avid fan of Team Avery Essex and
                    AnnaKennedyOnline, approached us to work together on a
                    charity football match. We were blessed with the support of
                    Bowers football club to be able to put together a fun day
                    and celebrity football match to raise funds to help the
                    homeless, run by the inspirational Sky Butcher and
                    AnnaKennedyOnline. The day was a huge success, and both
                    charities received an equal split of the proceeds.
                  </div>
                }
                direction="row"
              />
              <Event
                title="Reece's Rainbow Ball"
                date="13th July 2019"
                image={Reece}
                text={
                  <div>
                    Team Avery Essex member and firm friend Karen Hawley sadly
                    lost her son Reece to a brain tumour several years ago.
                    Reece's story touched us, as well as many others. His
                    parents determination to continue his legacy led to the
                    birth of the{" "}
                    <a
                      href="http://www.reecehawleyfoundation.co.uk/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Reece Hawley Foundation
                    </a>
                    . Reece's family fundraise to send young people nearing the
                    end of their lives to build memories with their families.
                    The family work tirelessly for their charity, so we wanted
                    to give them a night to reflect and raise funds to help
                    support Reece's legacy. The event raised an impressive
                    £5428.40 for some incredible people. We are privileged to
                    help them continue their work. Thank you for everything you
                    do.
                  </div>
                }
                direction="row-reverse"
              />
              <Event
                title="Thundersley Primary - Charity Fun Day and This is Me Presentation"
                date="June 2019"
                image={Thundersley}
                text={
                  <div>
                    We are happy to introduce honorary member Ruby Avery! When
                    asked to pitch a charity at school, she chose Team Avery as
                    she truly believes in what we do as a team and helping
                    others. We were approached by her school who, through Ruby's
                    wishes, invited Aston to do his{" "}
                    <AniLink to="/thisisme/" paintDrip hex="#d9e5fd">
                      This is Me
                    </AniLink>{" "}
                    presentation, and DJ on the school field! After quizzing the
                    students on what they learned, we were humbled by their
                    understanding of autism and really felt that this school in
                    particular is willing to get involved and give back to the
                    community!
                  </div>
                }
                direction="row"
              />
              <Event
                title="Bowers SEN Disco"
                date="April 2019"
                image={Sen}
                text={
                  <div>
                    The SEN disco was a great night! Designed to allow a chat
                    with some parents we met while at Aston's{" "}
                    <AniLink to="/thisisme/" paintDrip hex="#d9e5fd">
                      This is Me{" "}
                    </AniLink>{" "}
                    presentations and allow their children to have some fun. We
                    raised a great amount of money for{" "}
                    <AniLink to="/pamper/" paintDrip hex="#d9e5fd">
                      Aston's Pamper Project
                    </AniLink>{" "}
                    as well!{" "}
                  </div>
                }
                direction="row-reverse"
              />
              <Event
                title="This is Me - Hariss Academy"
                date="March 2019"
                image={Hariss}
                text={
                  <div>
                    Team members Mandy and Keith accompanied Aston to the
                    Thurrock based Hariss academy where Aston delivered his
                    inspirational video,{" "}
                    <AniLink to="/thisisme/" paintDrip hex="#d9e5fd">
                      This is Me
                    </AniLink>
                    . He received a great reception and the students and staff
                    alike were pleased with the content and understood what we
                    were trying to deliver. Thanks to the academy for having us!
                  </div>
                }
                direction="row"
              />
              <Event
                title="This is Me - Northlands School"
                date="January 2019"
                image={Northlands}
                text={
                  <div>
                    Aston and his parents went into a local school and met up
                    with some young parents. He treated them to a short chat
                    about himself and how he's overcome so much. He wanted to
                    give some hope to the parents that are unclear of their
                    future in parenting children with Special Educational Needs
                    and giving them a reason to see the positives. It was a
                    hugely successful day and many new friendships were formed.
                  </div>
                }
                direction="row-reverse"
              />
              <Event
                title="This is Me - Castledon School"
                date="December 2018"
                image={Castledon}
                text={
                  <div>
                    What a venue to launch Aston's inspiring film! Castledon was
                    Aston's old school, so it seemed fitting for him to launch
                    his new film here. He also talked to an assembly of students
                    to answer any questions they had. Firm friendships were
                    formed, and the promise of working with Castledon moving
                    forward. Aston, Keith and Dawn stayed on with some of the
                    parents when the students returned to class, and gave them
                    an insight into Astons journey. Thanks to Castledon for a
                    great day!
                  </div>
                }
                direction="row"
              />
              <Event
                title="Aston's Way - Woodlands School"
                date="November 2018"
                image={Woodlands}
                text={
                  <div>
                    <AniLink to="/astonsway/" paintDrip hex="#d9e5fd">
                      Aston's Way
                    </AniLink>{" "}
                    (in collaboration with Training 2 Care) saw an Autism
                    Reality bus taken into a local senior school, Woodlands. A
                    number of staff were trained in sensory issues by going
                    through the experience the bus offered. They were also
                    offered a full training session on autism and understanding
                    various techniques to support somebody who has it. A very
                    insightful day for all involved!
                  </div>
                }
                direction="row-reverse"
              />
              <Event
                title="Bowers Movie Theme Disco"
                date="August 2018"
                image={Movie}
                text="The movie themed Disco was to raise more funds for Joshua's Carriage after Aston's skydive. With a 'Night at the Movies' theme, the night saw everyone dressed as movie characters! Everyone had a great time raising money for an even greater cause."
                direction="row"
              />
              <Event
                title="Joshua's Carriage Skydive"
                date="5th August 2018"
                image={Skydive}
                text="Aston pulled off an amazing sponsored skydive to raise money for a local boy, Joshua, who has autism, dystonia, and cerebral palsy. The money went towards Joshua's Carriage, which funded an electric wheelchair and accessories for the 6 year old to have a bit more independence, and not have to rely on his mum to push him around in his manual wheelchair. Well done to Aston for doing something so brave!"
                direction="row-reverse"
              />
              <Event
                title="Aston's Way - Police and Fire Service Training"
                date="July 2018"
                image={PoliceWay}
                text={
                  <div>
                    Sadly as a child Aston has a negative experience with the
                    police, which was escalated due to a lack of understanding
                    by the police of Aston's autism and sensory issues. After
                    reflecting on his experience, Aston decided that more
                    understanding was paramount within the emergency services.
                    With the support of his parents and local police inspector
                    Steven Parry, Aston's dreams were realised. Over 100
                    firefighters and police officers went through the day at
                    Basildon Fire Station and were given the opportunity to
                    experience the Autism Reality Bus, with Aston and his
                    parents giving some presentations on living with autism.
                    Aston's ultimate aim is for all new recruits to be offered
                    this opportunity.{" "}
                  </div>
                }
                direction="row"
              />
              <Event
                title="80s Night for Aston's Pamper Project"
                date="April 2018"
                image={Eighties}
                text={
                  <div>
                    A great fundraising night for{" "}
                    <AniLink to="/pamper/" paintDrip hex="#d9e5fd">
                      Aston's Pamper Project
                    </AniLink>
                    , with a nostalgic 80's dress theme! A great time was had by
                    all. The money raised from this night went to helping
                    deserving people, treating them to a luxurious pamper. If
                    you'd like to find out more, or even nominate someone, click
                    the link!
                  </div>
                }
                direction="row-reverse"
              />
              <Event
                title="Charity Football Fun Day with AnnaKennedyOnline"
                date="August 2017"
                image={Football}
                text={
                  <div>
                    An amazing day for all involved. The day saw two football
                    teams face off, Celeb FC and Team AKO (AnnaKennedyOnline).
                    We raised £3000! Thanks so much to Shelia Hunt and her team
                    at Bowers FC, plus the support of Celeb FC alongside
                    performers from Pineapple Performing Arts!
                  </div>
                }
                direction="row"
              />
              <Event
                title="Autistic and Proud Fundraising Party"
                date="September 2015"
                image={Proud}
                text={
                  <div>
                    Autistic and Proud was definitely a night to remember. We
                    raised an amazing £2131 for AnnaKennedyOnline, thanks to the
                    amazing people who attended. There was lots of singing and
                    dancing! We really do think the night helped more people to
                    become autism aware.
                  </div>
                }
                direction="row-reverse"
              />
              <Event
                title="JJ Night"
                date="November 2013"
                image={JJ}
                text="JJ Night was a disco and auction to raise money for two very special local boys, Joshua and Joe. Joshua has celebral palsy, dystonia, and autism, while Joe was on dialysis. The funds raised helped to purchase specially made toys, including a custom built sandpit. It also helped take the children from the dialysis unit Joe was staying at for a day out."
                direction="row"
              />
            </Container>
          </Grid>
        </Grid>
      </div>
    </>
  )
}
